:root {
  --main-color: #2D4856;
}

$main-color: #2D4856;
$secondary-color: #E0B77D;
$main-font: 'Akko',
Arial,
sans-serif;
$secondary-font: 'Akko',
Arial,
sans-serif;