@font-face {
  font-family: 'Akko';
  src: url('../fonts/akko-pro-thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Akko';
  src: url('../fonts/akko-pro-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Akko';
  src: url('../fonts/akko-pro-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Akko';
  src: url('../fonts/akko-pro-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}