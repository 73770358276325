.container {
  width: 100%;
  max-width: 1368px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  &-fluid {
    padding: 0 8.4vw;
    max-width: 100vw;
  }

  @media (min-width: 1368px) {
    padding-left: 0;
    padding-right: 0;
  }
}

