.textarea {
  height: 185px;
  border: 2px solid #fff;
  padding: 16px;

  &::-webkit-input-placeholder {
    color: #fff;
  }

  &:-moz-placeholder {
    color: #fff;
  }

  &::-moz-placeholder {
    color: #fff;
  }

  &:-ms-input-placeholder {
    color: #fff;
  }

  &::placeholder {
    color: #fff;
  }

  @media (min-width: 768px) {
    padding: 24px;
  }
}