.page-detail {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  span {
    width: 100%;
    height: 100%;
    border-right: 1px solid rgba(#fff, .08);

    &:last-child {
      display: none;
    }
  }

  &-wrapper {
    position: relative;
    z-index: 1;
  }

  @media (min-width: 768px) {
    span {
      &:last-child {
        display: initial;
      }
    }
  }
}