.contact-hero {
  padding-top: 120px;

  &-title {
    position: relative;
    width: fit-content;
    padding: 30px 0;
    margin: 0 auto 50px;
    font-size: clamp(48px, 12vw, 180px);
    letter-spacing: 2px;
    font-weight: 200;
    color: #fff;
    z-index: 2;
  }

  @media (min-width: 1300px) {
    padding-top: 180px;
  }
}