button,
a,
input[type=submit] {
  &.button {
    position: relative;
    @include flexCenter;
    display: flex;
    @include transition;
    width: fit-content;
    height: 42px;
    border: none;
    padding: 10px 0;
    border-bottom: 2px solid $secondary-color;
    background-color: transparent;
    text-transform: uppercase;
    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    cursor: pointer;

    &-white {
      border-color: #fff;

      .button-arrow {
        border-color: #fff;

        &::before {
          background-color: #fff;
        }
      }
    }

    &:hover {
      border-color: darken($secondary-color, 10%);

      .button-arrow {
        border-color: darken($secondary-color, 10%);

        &::before {
          background-color: darken($secondary-color, 10%);
        }
      }
    }
  }
}

button,
a,
input[type=submit] {
  &.button-fill {
    position: relative;
    @include flexCenter;
    @include transition;
    width: fit-content;
    height: 70px;
    border: none;
    padding: 0 46px;
    background-color: $secondary-color;
    text-transform: uppercase;
    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: darken($secondary-color, 10%);

      .button-arrow {
        border-color: darken(#fff, 10%);

        &::before {
          background-color: darken(#fff, 10%);
        }
      }
    }

    .button-arrow {
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;

      &::before {
        background-color: #fff;
      }
    }
  }
}

button,
a,
input[type=submit] {
  &.button-shrunk {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 0px 1fr;
    @include transition;
    width: fit-content;
    height: 70px;
    border: none;
    padding: 0 38px;
    background-color: $secondary-color;
    text-transform: uppercase;
    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    cursor: pointer;

    span {
      overflow: hidden;
      transform: scaleX(0);
      transform-origin: left;
    }

    &:hover {
      grid-template-columns: 160px 1fr;
      background-color: darken($secondary-color, 10%);

      span {
        margin-right: 25px;
        transform: scaleX(1);
        @include transition;
      }

      .button-arrow {
        border-color: darken(#fff, 10%);

        &::before {
          background-color: darken(#fff, 10%);
        }
      }
    }

    .button-arrow {
      margin-left: 0;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;

      &::before {
        background-color: #fff;
      }
    }
  }
}

.button-arrow {
  margin-left: 25px;
  position: relative;
  width: 14px;
  height: 14px;
  border-right: 2px solid $secondary-color;
  border-bottom: 2px solid $secondary-color;
  @include transition;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 1px;
    width: 150%;
    height: 2px;
    background-color: $secondary-color;
    transform: rotate(45deg);
    transform-origin: 0 0;
    @include transition;
  }
}