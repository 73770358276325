.home-contact {
  position: relative;
  padding-bottom: 65px;

  &-title {
    display: flex;
    align-items: flex-end;
    background-color: var(--main-color);
    margin-bottom: 50px;
    line-height: 58px;
    font-weight: 500;
    font-size: 38px;
    color: #fff;

    &::after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      margin-left: 30px;
      margin-bottom: 17px;
      background-color: $secondary-color;
    }
  }

  &-form {
    z-index: 1;
    border-top: 4px solid $secondary-color;
    background-color: rgba(#666, .5);
    backdrop-filter: blur(35px);
    -webkit-backdrop-filter: blur(35px);
    margin: 0 8.4vw;
    padding: 50px 8.4vw;

    &-response {
      width: 100%;
      margin-top: 30px;
    }
  }

  &-image {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    z-index: -1;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  @media (min-width: 768px) {

    &-title {
      margin-bottom: 60px;
    }

    &-form {
      margin-top: 120px;
      max-width: 550px;
    }

    &-image {
      width: 70vw;
      height: calc(100% - 120px);
      right: 0;
      left: initial;
    }

  }

  @media (min-width: 1300px) {
    &-form {
      max-width: 1225px;
      width: 70vw;
      padding-top: 110px;
      padding-bottom: 110px;

      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &-wrap {
        width: calc(50% - 4.2vw);
      }

      .input-wrapper {
        &:not(:last-child) {
          .input {
            margin-bottom: 80px;
          }
        }
      }
    }
  }
}