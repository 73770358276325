.team-hero {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 100px 0;
  overflow: hidden;

  &-title {
    position: absolute;
    top: 50%;
    left: 10vw;
    transform: translateY(-50%);
    font-size: clamp(48px, 12vw, 180px);
    letter-spacing: 2px;
    font-weight: 200;
    color: #fff;
    z-index: 2;
  }

  &-logo {
    transform: translateX(10vw);
    max-width: 70vw;
  }

  @media (min-width: 1024px) {
    padding-bottom: 200px;

    &-title {
      transform: translateY(-100%);
    }
  }

  @media (min-width: 1300px) {
    &-title {
      left: 16vw;
    }
  }

  @media (min-width: 1600px) {
    &-logo {
      max-width: 80vw;
    }
  }

}