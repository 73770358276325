.home-hero {
  position: relative;
  height: calc(100vh - 115px);

  &-content {
    padding: 0 8.4vw;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 800px;
    z-index: 10;

    h1 {
      margin-bottom: 30px;
      max-width: 800px;
      font-weight: 200;
      line-height: 150%;
      font-size: clamp(18px, 5vw, 48px);
      color: #fff;
    }
  }

  &-slide {
    position: relative;
    z-index: 9;

    &-figure {
      position: relative;
      width: 100%;
      height: calc(100vh - 115px);

      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .34);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-arrows {
      position: absolute;
      left: 38px;
      bottom: 32px;
    }

    &-arrow {
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        path {
          stroke: $secondary-color;
        }
      }

      &--right {
        svg {
          transform-origin: right;
          transform: scale(-1);
        }
      }
    }

    &-bullets {
      position: absolute;
      display: flex;
      gap: 30px;
      left: 160px;
      bottom: 40px;
    }

    &-bullet {
      padding: 0;
      border: none;
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 0;
      cursor: pointer;

      &.glide__bullet--active {
        background-color: $secondary-color;
      }
    }
  }

  .glide {
    overflow: hidden;

    &__slides {
      display: flex;
    }
  }

  &-detail {
    display: none;
  }

  @media (min-width: 768px) {
    &-content {
      padding: 0;
      padding-bottom: 150px;
      transform: translateY(-50%);
      max-width: 70%;
      left: 20%;

      h1 {
        line-height: 140%;
      }
    }

    &-detail {
      display: block;
      position: absolute;
      right: 0;
      bottom: -80px;
      z-index: 11;
      width: 100%;
      max-width: 60%;

      path {
        fill: #fff;
      }
    }
  }

  @media (min-width: 1300px) {
    height: calc(100vh - 135px);

    &-slide {
      padding-right: 100px;
    }

    .glide {
      &__slides {
        gap: 50px;
      }
    }

    &-detail {
      bottom: -140px;
    }
  }

  @media (min-width: 1800px) {
    &-detail {
      bottom: -220px;
    }
  }
}