.input {
  width: 100%;
  height: 50px;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #fff;
  margin-bottom: 20px;
  padding-left: 0;
  font-family: $main-font;
  font-size: 16px;
  color: #fff;

  &:focus {
    outline: none;
  }

  &-label {
    position: absolute;
    top: 50%;
    left: 0;
    @include transition;
    transform: translateY(-50%);
    letter-spacing: 1.2px;
    font-size: 16px;
    color: #fff;
    cursor: text;
  }

  &-wrapper {
    position: relative;
  }

  &:focus,
  &:valid {
    &~.input-label {
      top: 10%;
      font-size: 12px;
      color: rgba(#fff, .6);
    }
  }
}