.project-related {
  padding: 60px 0 0;

  &-title {
    display: flex;
    background-color: var(--main-color);
    align-items: flex-end;
    margin-bottom: 50px;
    line-height: 58px;
    font-weight: 500;
    font-size: 38px;
    color: #fff;

    &::after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      margin-left: 30px;
      margin-bottom: 17px;
      background-color: $secondary-color;
    }
  }

  &-list {
    padding: 0 8.4vw;
  }

  &-item {
    margin-bottom: 40px;
    position: relative;

    &:hover {
      .project-related-item-figure {
        img {
          transform: scale(1.1);
          opacity: .6;
        }
      }
    }

    &-figure {
      position: absolute;
      width: calc(100vw - 16.8vw);
      height: calc(120vw - 16.8vw);
      background-color: #000;
      max-width: 465px;
      max-height: 485px;
      overflow: hidden;
      z-index: 9;

      img {
        width: 100%;
        height: calc(120vw - 16.8vw);
        max-width: 465px;
        max-height: 485px;
        object-fit: cover;
        object-position: center;
        @include transition(7s);
        opacity: .5;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: 0 8.4vw;
      width: calc(100vw - 16.8vw);
      height: calc(120vw - 16.8vw);
      max-width: 465px;
      max-height: 485px;
      z-index: 10;
    }

    &-title {
      width: 100%;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 28px;
      color: #fff;
    }

    &-description {
      width: 100%;
      margin-bottom: 0;
    }

    .button-shrunk {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  @media (min-width: 768px) {

    &-list {
      gap: 60px;
      display: flex;
      overflow-x: scroll;
      will-change: transform;
      user-select: none;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      cursor: grab;

      &.active {
        cursor: grabbing;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-item {
      &-content {
        padding: 0 40px;
      }
    }

  }

}