.about-hero {
  position: relative;

  &-title {
    left: 10vw;
    top: 150px;
    position: absolute;
    letter-spacing: 2px;
    font-weight: 200;
    font-size: clamp(48px, 12vw, 180px);
    color: #fff;
  }

  &-figure {
    width: 80vw;
    height: 250px;
    margin-left: 20vw;

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: left;
    }
  }

  &-detail {
    position: absolute;
    bottom: 20px;
    padding-left: 5vw;
    z-index: -1;

    svg {
      height: 125px;
    }
  }

  @media (min-width: 500px) {
    &-title {
      top: initial;
      bottom: 70px;
    }

    &-figure {
      width: 70vw;
      height: 400px;
      margin-left: 30vw;

      img {
        height: 400px;
      }
    }

    &-detail {
      svg {
        height: 200px;
      }
    }
  }

  @media (min-width: 1024px) {
    &-title {
      bottom: 100px;
    }

    &-figure {
      height: 600px;

      img {
        height: 600px;
      }
    }

    &-detail {
      svg {
        height: 300px;
      }
    }
  }

  @media (min-width: 1300px) {
    &-title {
      left: 13.8vw;
      bottom: 120px;
    }

    &-figure {
      height: 825px;
      width: 79vw;
      margin-left: 21vw;

      img {
        height: 825px;
      }
    }

    &-detail {
      padding-left: 8.4vw;

      svg {
        height: 430px;
      }
    }
  }
}