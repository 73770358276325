.contact-form {
  padding-bottom: 100px;

  &-form {
    padding: 60px 8.4vw;
    background-color: rgba(#fff, .05);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }

  &-response {
    width: 100%;
    margin-top: 30px;
  }

  &-content {
    margin-top: -25px;
    padding: 8.4vw;
    background-color: $secondary-color;

    p {
      margin-bottom: 30px;
    }

    a {
      line-height: 22px;
      color: #fff;
    }

    .social {
      &-item {
        a {
          margin-bottom: 20px;
          width: 60px;
          height: 60px;
          @include flexCenter;
          border: 1px solid #fff;
          font-size: 20px;

          &:hover {
            background-color: rgba(#fff, .1);
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .container-fluid {
      display: flex;
      align-items: flex-start;
    }

    &-form {
      width: 60%;
    }

    &-content {
      width: calc(40% + 50px);
      margin-left: -20px;
      margin-top: 50px;
    }
  }

  @media (min-width: 1300px) {
    padding-bottom: 200px;

    &-form {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 110px;
      padding-bottom: 90px;

      .input-wrapper {
        &:not(:last-child) {
          .input {
            margin-bottom: 80px;
          }
        }
      }
    }

    &-content {
      width: calc(30% + 50px);
      margin-left: -50px;
      padding-top: 110px;
      padding-bottom: 90px;
    }

    &-wrap {
      width: calc(50% - 4.2vw);
    }
  }
}