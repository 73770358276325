.project-about {
  position: relative;
  z-index: 10;
  padding: 0 8.4vw 80px;
  margin-top: -80px;

  &-content {
    padding: 120px 8.4vw 60px;
    background-color: $secondary-color;
    position: relative;

    p {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: -65px;
      left: 4.2vw;
      width: 1px;
      height: 100%;
      background-color: #fff;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 100px;

    &-content {
      padding: 110px 160px;
      max-width: 900px;

      &::before {
        top: -115px;
        left: 100px;
      }
    }
  }

  @media (min-width: 1300px) {
    padding-right: 0;
    padding-left: 30vw;
  }

  @media (min-width: 1700px) {
    padding-right: 0;
    padding-left: 38vw;
    margin-top: -125px;
  }
}