.about-content {
  &-first {
    padding-bottom: 50px;

    &-content {
      padding: 80px 0 50px;

      p {
        line-height: 24px;
      }
    }
  }

  &-second {
    padding: 80px 0;

    &-content {
      padding: 30px 8.4vw;
      background-color: var(--main-color);
    }
  }

  @media (min-width: 1024px) {
    &-first {
      padding-top: 100px;

      .container-fluid {
        display: flex;
        gap: 70px;
        align-items: center;
        flex-direction: row-reverse;
      }

      &-content {
        padding: 0;
      }

      &-image {
        min-width: 40%;
        width: 100%;
      }
    }

    &-second {
      display: flex;
      gap: 70px;
      align-items: start;
      padding-top: 100px;

      &-content {
        padding-right: 0;
      }

      &-image {
        min-width: 50%;
        width: 100%;
      }
    }
  }

  @media (min-width: 1300px) {
    &-first {
      .container-fluid {
        gap: 0px;
      }

      &-image {
        width: 50%;
        margin-right: 80px;
      }

      &-content {
        max-width: 680px;
      }
    }

    &-second {
      &-content {
        padding-top: 120px;

        .button {
          margin-top: 60px;
        }
      }
    }
  }

  @media (min-width: 1800px) {
    &-second {
      padding-bottom: 120px;
      gap: 160px;

      &-content {
        padding-top: 170px;
      }
    }
  }
}