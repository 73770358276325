.project-gallery {
  padding: 80px 8.4vw;

  .glide {
    overflow: hidden;

    &__slides {
      display: flex;
    }

    &__slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  img {
    max-height: 90vh;
  }

  &-arrows {
    margin-top: 40px;
    @include flexCenter;
    gap: 30px;
  }

  &-arrow {
    width: 60px;
    height: 60px;
    @include flexCenter;
    background-color: transparent;
    border: 1px solid #fff;
    font-size: 20px;
    @include transition;
    cursor: pointer;

    &--right {
      svg {
        transform-origin: center;
        transform: scale(-1);
      }
    }

    &:hover {
      background-color: rgba(#fff, .1);
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 150px;
  }
}