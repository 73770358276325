.header {
  padding: 30px 8.4vw;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.transparent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    z-index: 1000;
  }

  &-hamburger {
    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: 20px;
      position: relative;
      z-index: 1000;

      span {
        width: 100%;
        height: 2px;
        background-color: #fff;
      }
    }
  }

  &-menu {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 0;
    height: 100vh;
    padding-top: 90px;
    overflow: hidden;
    background-color: var(--main-color);
    z-index: 100;

    .menu {
      list-style: none;

      &-item {
        overflow: hidden;
        margin-bottom: 5px;
        margin: 35px 0 35px 40px;

        a {
          position: relative;
          display: flex;
          flex-direction: column;
          width: fit-content;
          height: 20px;
          overflow: hidden;
          text-transform: uppercase;
          @include font(400, 20px, #fff, $main-font, 20px);

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #fff;
            bottom: 0;
            left: 0;
            pointer-events: none;
            transform-origin: 100% 50%;
            transform: scale3d(0, 1, 1);
            transition: transform 0.3s;
          }

          &:hover {
            &::before {
              transform-origin: 0% 50%;
              transform: scale3d(1, 1, 1);
            }
          }
        }

        &-button {
          @extend .menu-item;

          a {
            border: none;
            border-radius: 0;
          }
        }
      }
    }

    .social {
      display: flex;
      align-items: center;
      gap: 30px;
      margin: 35px 0 35px 40px;
      font-size: 25px;

      a {
        color: #fff;
      }
    }
  }

  &-night-toggle {
    margin-left: 40px;

    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-transform: uppercase;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }

    input {
      display: none;

      &:checked~.header-night-toggle-element {
        justify-content: flex-end;
        background-color: #fff;

        &::before {
          background-color: var(--main-color);
        }
      }
    }

    &-element {
      display: flex;
      align-items: center;
      justify-content: start;
      width: 70px;
      height: 40px;
      border-radius: 20px;
      margin-left: 15px;
      border: 2px solid #fff;
      @include transition;

      &::before {
        content: '';
        display: block;
        width: 28px;
        height: 28px;
        border-radius: 28px;
        margin: 0 5px;
        background-color: #fff;
        @include transition;
      }
    }
  }

  @media (min-width: 1300px) {
    padding-top: 45px;
    padding-bottom: 35px;

    &-container {
      justify-content: space-between;
      max-width: 1368px;
    }

    &-hamburger {
      display: none;
    }

    &-menu {
      position: relative;
      width: initial;
      height: initial;
      left: initial;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      border-left: 0;
      background-color: transparent;
      transform: translateX(0);
      padding-top: 0px;
      padding-bottom: 0;
      order: 2;
      z-index: 1;

      .header-night-toggle {
        width: 100%;
        margin-left: 100px;
      }

      .menu {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0;
        margin-right: 0;

        &-item {
          margin: 0;
          margin-right: 40px;
          padding-bottom: 10px;
          padding-top: 10px;
          position: relative;
          display: block;
          animation: none;
          @include transition;

          &:last-child {
            margin-right: 0;
          }

          a {
            padding: 0 0 5px;
            font-size: 16px;
            line-height: 22px;
            height: 22px;
            color: #fff;
            font-family: $main-font;
            white-space: nowrap;

            &::before {
              background-color: #fff;
            }
          }
        }
      }

      .social {
        margin: 0;
        margin-left: 70px;
      }
    }
  }

  @media (min-width: 1600px) {
    &-menu {
      .menu {
        &-item {
          margin-right: 90px;
        }
      }
    }
  }

  @media (min-width: 1800px) {
    &-menu {
      .header-night-toggle {
        margin-left: 200px;
      }
    }
  }
}