.blog-list {
  margin-bottom: 100px;

  ul {
    padding: 0 8.4vw;
    background-color: var(--main-color);
  }
}

.blog-item {
  margin-bottom: 50px;

  &:hover {
    .home-blog-item-figure {
      img {
        transform: scale(1.1);
      }
    }
  }

  &-figure {
    margin-bottom: 20px;
    height: 250px;
    overflow: hidden;

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: center;
      @include transition(7s);
    }
  }

  &-date {
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
  }

  &-title {
    line-height: 32px;
    font-weight: 500;
    font-size: 28px;
    color: #fff;
  }
}

@media (min-width: 1024px) {
  .blog-list {
    ul {
      display: grid;
      gap: 50px;
      grid-template-columns: 1fr 1fr;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        max-width: 670px;
      }
    }
  }

  .blog-item {
    margin-bottom: 100px;
    max-width: 670px;

    &-date {
      font-size: 16px;
    }

    &-title {
      font-size: 32px;
      line-height: 36px;
    }
  }
}

@media (min-width: 1300px) {
  .blog-list {
    gap: 100px;
  }

  .blog-item {
    &-figure {
      height: 380px;

      img {
        height: 380px;
      }
    }
  }
}