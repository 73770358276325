.page-content {
  padding: 100px 8.4vw;

  &-title {
    font-size: 40px;
  }

  p,
  img {
    margin-bottom: 40px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 40px;
    font-weight: 500;
    color: #fff;
  }

  p,
  em,
  blockquote,
  ul,
  ol,
  li {
    margin-bottom: 40px;
    @include font(400, 16px, #fff, $main-font, 24px);
  }

  ul {
    list-style-type: disc;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  @media (min-width: 1024px) {
    padding: 140px 0 100px;
    max-width: 940px;
    margin: 0 auto;
  }
}