.team-list {
  padding: 0 8.4vw;

  &-item {
    margin-bottom: 80px;
    text-align: center;

    &:hover {
      img {
        -webkit-filter: none;
        filter: none;
      }
    }

    &-figure {
      width: 260px;
      height: 260px;
      margin: 0 auto 25px;

      img {
        width: 260px;
        height: 260px;
        object-fit: cover;
        border-radius: 300px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        @include transition;
      }
    }

    &-title {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 24px;
      color: #fff;
    }

    &-about {
      margin-bottom: 30px;
    }

    &-description {
      background-color: var(--main-color);
      color: #B5B5B5;

      p {
        color: #B5B5B5;
      }
    }
  }

  @media (min-width: 1024px) {
    .page-detail {
      span {
        width: 20%;

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }
    }

    &-item {
      text-align: left;
      margin-bottom: 200px;

      article {
        display: grid;
        align-items: center;
        grid-template-columns: 320px 1fr;
        gap: 100px;
      }

      &-figure {
        width: 360px;
        height: 360px;
        margin-bottom: 0;

        img {
          width: 360px;
          height: 360px;
        }
      }

      &-title {
        font-size: 38px;
      }

      &-about {
        margin-bottom: 65px;
      }
    }
  }

  @media (min-width: 1300px) {
    padding: 0 8.4vw 0 9vw;

    &-item {
      article {
        grid-template-columns: 420px 1fr;
        gap: 130px;
      }

      &:nth-child(even) {
        padding-left: 9vw;
      }

      &-content {
        max-width: 675px;
      }

      &-figure {
        width: 420px;
        height: 420px;

        img {
          width: 420px;
          height: 420px;
        }
      }
    }
  }

  @media (min-width: 1800px) {
    padding: 0 8.4vw 0 16vw;

    &-item {

      &:nth-child(even) {
        padding-left: 9vw;
      }
    }
  }
}