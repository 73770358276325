.project-comparison {
  display: flex;
  justify-content: center;
  padding: 0 8.4vw;

  &-container {
    position: relative;
    width: 300px;
    height: 117px;
  }

  &-img {
    position: absolute;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  &-img img {
    display: block;
    width: 300px;
    height: 117px;
  }

  &-overlay {
    border-right: 4px solid #fff;

    img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }

  &-slider {
    position: absolute;
    z-index: 9;
    @include flexCenter;
    gap: 10px;
    cursor: ew-resize;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    transform: translateX(-4px);
    font-size: 20px;
  }

  @media (min-width: 768px) {

    &-container,
    &-img img {
      width: 640px;
      height: 250px;
    }

  }

  @media (min-width: 1024px) {

    &-container,
    &-img img {
      width: 850px;
      height: 331px;
    }

    &-slider {
      width: 70px;
      height: 70px;
    }

  }

  @media (min-width: 1300px) {

    &-container,
    &-img img {
      width: 1145px;
      height: 445px;
    }

  }

  @media (min-width: 1600px) {

    &-container,
    &-img img {
      width: 1335px;
      height: 520px;
    }

  }

  @media (min-width: 1800px) {

    &-container,
    &-img img {
      width: 1515px;
      height: 590px;
    }

  }
}