.cookies {
  display: none;
  position: fixed;
  left: 40px;
  bottom: 15px;
  max-width: 420px;
  width: calc(100% - 60px);
  padding: 20px;
  padding-right: 50px;
  background-color: rgba(#16232a, .7);
  backdrop-filter: blur(35px);
  -webkit-backdrop-filter: blur(35px);
  z-index: 1000;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);

  p {
    margin-bottom: 0;

    a {
      color: #fff;
    }
  }

  &-button {
    display: block;
    width: fit-content;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: var(--main-color);
    color: #fff;

    &:hover {
      background-color: darken($main-color, 10%);
    }
  }

  &-close {
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
    @include transition;
    background-color: transparent;
    color: var(--main-color);
    border: none;
    font-size: 16px;

    &:hover {
      color: #fff;
    }
  }

  &.accepted {
    display: none;
    animation: out .3s forwards;
  }

  &.not-accepted {
    display: block;
  }

  @media (min-width: 768px) {
    padding: 35px 80px 35px 40px;
  }
}