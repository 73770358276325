/*!
    Theme Name: 
    Theme URI: http://studiomou.com/
    Author: Leo Amaral
    Author URI: http://leoamaraldev.com.br/
    Description: 
    Version: 1.0
    License: GNU General Public License v2 or later
    License URI: http://www.gnu.org/licenses/gpl-2.0.html
    Text Domain: studiomou
    Tags: 
*/
