.footer {
  background-color: var(--main-color);

  &-newsletter {
    background-color: var(--main-color);
    color: #fff;

    &-form {
      padding: 80px 8.4vw;

      h3 {
        margin-bottom: 25px;
        font-size: 48px;
        font-weight: 400;
      }

      form {
        position: relative;
      }

      input {
        margin-bottom: 0;
      }

      .button-white {
        padding: 0;
        border: none;
        background-color: transparent;
        position: absolute;
        bottom: 18px;
        right: 0;
        cursor: pointer;
      }
    }

    &-response {
      margin-top: 10px;
    }

    &-map {
      width: 100%;
      height: 220px;
      background: #333;

      img {
        object-fit: cover;
        object-position: center;
        height: 220px;
      }
    }
  }

  &-info {
    font-size: 16px;
    color: #fff;
  }

  &-menu {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
    padding: 20px 8.4vw;
    background-color: var(--main-color);

    a {
      line-height: 40px;
      font-size: 16px;
      color: #fff;
    }
  }

  &-contact {
    width: fit-content;
    padding: 20px 8.4vw;
    background-color: var(--main-color);

    a {
      line-height: 32px;
      font-size: 16px;
      color: #fff;
    }
  }

  &-social {
    display: flex;
    align-items: center;
    gap: 30px;

    a {
      font-size: 20px;
    }
  }

  &-copyright {
    padding: 20px 8.4vw;
    background-color: var(--main-color);

    p {
      line-height: 16px;
      font-size: 14px;
      color: #A7A7A7;
    }
  }

  @media (min-width: 768px) {
    padding-top: 80px;

    &-newsletter {
      &-map {
        height: 320px;

        img {
          height: 320px;
        }
      }
    }

    &-info {
      display: flex;
      gap: 60px;
      padding: 0 8.4vw;
      margin-top: 60px;
    }

    &-menu {
      gap: 40px;
      padding: 0;
      margin-top: 0;
      margin-bottom: 50px;
      padding-bottom: 50px;
    }

    &-contact {
      padding-top: 0;
    }
  }

  @media (min-width: 1300px) {
    padding-top: 180px;

    &-newsletter {
      display: flex;
      gap: 90px;
      justify-content: space-between;

      &-form {
        padding: 0;
        padding-left: 8.4vw;
      }

      &-map {
        max-width: 60vw;
      }
    }

    &-menu {
      gap: 100px;
    }

    &-info {
      margin-top: 0;
    }

    &-contact {
      padding-top: 60px;
      display: flex;
      gap: 60px;
    }

    &-social {
      flex-direction: column;
    }
  }

  @media (min-width: 1500px) {
    &-info {
      padding-right: 0;
      justify-content: space-between;
    }

    &-contact {
      padding-left: 100px;
      padding-right: 0;
      width: 60vw;
      gap: 100px;
    }
  }
}