.projects-hero {
  text-align: center;
  padding-top: 120px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &-title {
    width: fit-content;
    padding: 30px 0;
    background-color: var(--main-color);
    font-size: clamp(48px, 12vw, 180px);
    letter-spacing: 2px;
    font-weight: 200;
    color: #fff;
    z-index: 2;
  }

  &-navigation {
    padding: 60px 0 120px;
    background-color: var(--main-color);

    ul {
      display: flex;
      gap: 60px;
    }

    button {
      padding: 10px 0;
      background-color: transparent;
      text-transform: uppercase;
      font-family: $main-font;
      border: none;
      border-bottom: 2px solid $secondary-color;
      font-size: 16px;
      color: #fff;
      cursor: pointer;

      &.active {
        color: $secondary-color;
        border-width: 4px;
      }
    }
  }

  @media (min-width: 1024px) {
    &-navigation {
      padding-top: 100px;

      ul {
        gap: 180px;
      }

      button {
        font-size: 20px;
      }
    }
  }
}