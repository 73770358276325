.single-hero {
  padding: 60px 0;

  &-date {
    text-transform: uppercase;
  }

  &-title {
    margin-bottom: 30px;
    font-weight: 200;
    line-height: 150%;
    font-size: clamp(22px, 5vw, 100px);
    color: #fff;
  }

  &-figure {
    width: 100%;
    height: 400px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 400px;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    &-figure {
      height: 600px;

      img {
        height: 600px;
      }
    }
  }

  @media (min-width: 1024px) {
    &-figure {
      height: 730px;

      img {
        height: 730px;
      }
    }
  }
}