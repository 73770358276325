.project-hero {
  height: 100vh;
  position: relative;
  z-index: 5;

  &-title {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 0 8.4vw;
    transform: translateY(-50%);
    font-size: clamp(48px, 12vw, 100px);
    letter-spacing: 2px;
    font-weight: 200;
    color: #fff;
    z-index: 2;
  }

  &-figure {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100vh;
      opacity: .5;
    }
  }
}