.projects-list {
  &-item {
    margin-bottom: 15px;

    &.hide {
      display: none;
    }

    article {
      position: relative;
    }

    &:hover {
      .projects-list-item-figure {
        img {
          transform: scale(1.1);
          opacity: .8;
        }
      }
    }

    &-figure {
      width: 100%;
      height: 400px;
      background-color: #000;
      position: relative;
      overflow: hidden;
      z-index: 1;

      img {
        object-fit: cover;
        width: 100%;
        height: 400px;
        opacity: .5;
        @include transition(7s);
      }
    }

    &-content {
      position: absolute;
      width: 100%;
      top: 50%;
      max-width: calc(350px + 16.8vw);
      transform: translateY(-50%);
      padding: 0 8.4vw;
      z-index: 2;
    }

    &-title {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 30px;
      color: #fff;
    }

    &-description {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 1024px) {
    &-wrap {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }

    &-item {
      margin-bottom: 0;

      &:first-child {
        width: 100%;
      }

      &:not(:first-child) {
        width: calc(50% - 7.5px);

        .projects-list-item-content {
          top: initial;
          transform: none;
          bottom: 100px;
        }
      }

      &-figure {
        height: 600px;

        img {
          height: 600px;
        }
      }
    }
  }

  @media (min-width: 1300px) {

    &-item {
      &-figure {
        height: 670px;

        img {
          height: 670px;
        }
      }
    }
  }
}