.home-team {
  overflow: hidden;
  padding-bottom: 100px;

  &-title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
    line-height: 58px;
    font-weight: 500;
    font-size: 38px;
    color: #fff;

    &::after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      margin-left: 30px;
      margin-bottom: 17px;
      background-color: $secondary-color;
    }
  }

  &-list {
    padding: 0 8.4vw;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    will-change: transform;
    user-select: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    cursor: grab;

    &.active {
      cursor: grabbing;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-item {
    width: 40vw;

    &:hover {
      img {
        -webkit-filter: none;
        filter: none;
      }
    }

    &-figure {
      width: 40vw;
      height: 40vw;

      img {
        width: 40vw;
        height: 40vw;
        object-fit: cover;
        border-radius: 300px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        @include transition;
      }
    }

    &-content {
      width: 100%;
      padding-top: 20px;
      color: #fff;
      text-align: center;

      h1 {
        margin-bottom: 5px;
        letter-spacing: .5px;
        font-weight: 500;
        font-size: 14px;
      }

      p {
        font-size: 11px;
      }
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 150px;

    &-title {
      margin-bottom: 120px;
    }

    &-list {
      gap: 60px;
    }

    &-item {
      max-width: 420px;
      width: 35vw;

      &-content {
        padding-top: 30px;
        text-align: left;
        padding-left: 16%;
        position: relative;

        h1 {
          margin-bottom: 10px;
          font-size: 24px;
        }

        p {
          line-height: 24px;
          font-size: 16px;
        }

        &::after,
        &::before {
          content: '';
          position: absolute;
          left: 8%;
        }

        &::after {
          width: 1px;
          height: 250px;
          bottom: 0;
          background-color: #fff;
          z-index: 1;
        }

        &::before {
          width: 4px;
          height: 80px;
          bottom: 170px;
          background-color: $secondary-color;
          z-index: 2;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    &-item {
      &-figure {
        width: 420px;
        height: 420px;

        img {
          width: 420px;
          height: 420px;
        }
      }
    }
  }

  @media (min-width: 1300px) {
    padding-bottom: 260px;

    &-title {
      margin-bottom: 135px;
    }

    &-list {
      gap: 10vw;
    }
  }

  @media (min-width: 1500px) {
    &-list {
      gap: 17vw;
    }

    &-item {
      &-content {
        &::after {
          height: 300px;
        }

        &::before {
          height: 110px;
          bottom: 190px;
        }
      }
    }
  }
}