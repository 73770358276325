.blog-hero {
  margin-bottom: 100px;
  padding-top: 100px;

  &-title {
    position: relative;
    width: fit-content;
    padding: 30px 0;
    // margin: 0 auto -50px;
    margin: 0 auto;
    font-size: clamp(48px, 12vw, 180px);
    font-size: clamp(48px, 12vw, 90px);
    letter-spacing: 2px;
    font-weight: 200;
    color: #fff;
    z-index: 2;
  }

  &-construction {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: #fff;

    &~p {
      display: block;
      margin: 20px auto;
      text-align: center;
      max-width: 600px;
    }
  }

  &-featured {
    &-image {
      top: 0;
      left: 0;
      width: 100%;
      height: 250px;
      background-color: #000;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 250px;
        opacity: .5;
      }
    }

    &-content {
      position: relative;
      margin: -100px 8.4vw 0;
      padding: 8.4vw;
      background-color: #7E7F73;
      z-index: 1;
    }

    &-date {
      text-transform: uppercase;
      letter-spacing: 0.38px;
      font-size: 16px;
    }

    &-title {
      margin-bottom: 30px;
      letter-spacing: 0.91px;
      font-weight: 200;
      font-size: 28px;
      color: #fff;
    }
  }

  @media (min-width: 768px) {
    &-featured {
      &-image {
        height: 470px;

        img {
          height: 470px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    &-title {
      // margin-bottom: -75px;
    }

    &-featured {
      &-image {
        width: calc(100% - 8.4vw);
        height: 670px;

        img {
          height: 670px;
        }
      }

      &-content {
        margin-right: 0;
        margin-top: -260px;
        max-width: 950px;
        padding-top: 100px;
        padding-bottom: 70px;
        margin-left: auto;
      }

      &-title {
        font-size: 38px;
      }
    }
  }

  @media (min-width: 1300px) {
    &-title {
      // margin-bottom: -125px;
    }
  }
}