.home-about {
  padding: 100px 8.4vw;

  &-project {

    position: relative;
    margin-bottom: 50px;

    &-figure {
      max-width: 466px;
      width: 100%;
      height: 370px;
      background-color: #fff;
      overflow: hidden;

      img {
        width: 100%;
        height: 370px;
        object-fit: cover;
        object-position: center;
        @include transition(7s);
      }
    }

    &:hover {
      .home-about-project-figure {
        img {
          transform: scale(1.1);
        }
      }
    }

    .button-fill {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &-content {
    padding: 50px 0 60px;
    background-color: var(--main-color);

    h2 {
      max-width: 466px;
      margin-bottom: 30px;
      line-height: 58px;
      font-weight: 500;
      font-size: 38px;
      color: #fff;
    }
  }

  @media (min-width: 768px) {
    padding-top: 150px;
    padding-bottom: 140px;

    &-project {
      &-figure {
        height: 595px;

        img {
          height: 595px;
        }
      }

      &-horizontal {
        .home-about-project-figure {
          height: 370px;
          max-width: 670px;

          img {
            height: 370px;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    overflow: hidden;

    &-content {
      width: 100%;
      position: absolute;
      padding-left: 420px;
    }

    &-projects {
      display: flex;
      flex-direction: column;
    }

    &-project {
      margin-bottom: 0;
      width: fit-content;

      &-figure {
        max-width: 370px;
        height: 530px;
      }

      &:nth-child(odd) {
        &:not(:first-child) {
          margin-top: -200px;
        }
      }

      &:nth-child(even) {
        &:not(:nth-child(2)) {
          transform: translateY(-240px);
        }
      }

      &:nth-child(6) {
        transform: translateY(-480px) !important;
      }

      &-horizontal {
        margin-left: 420px;

        .home-about-project-figure {
          width: 420px;
        }
      }
    }
  }

  @media (min-width: 1300px) {
    padding-left: 20%;
    padding-top: 380px;
    gap: 110px;

    &-content {
      padding-top: 130px;
      padding-bottom: 50px;
    }

    &-project {
      &-horizontal {
        .home-about-project-figure {
          width: 480px;
        }
      }
    }
  }

  @media (min-width: 1500px) {
    &-content {
      padding-left: 570px;
    }

    &-project {
      &-figure {
        height: 595px;
        max-width: 466px;

        img {
          height: 595px;
        }
      }

      &-horizontal {
        margin-left: 570px;

        .home-about-project-figure {
          width: 555px;
        }
      }
    }
  }

  @media (min-width: 1800px) {
    &-project {
      &-horizontal {
        .home-about-project-figure {
          width: 675px;
        }
      }
    }
  }
}