.home-blog {
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 50px;

  &-title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
    line-height: 58px;
    font-weight: 500;
    font-size: 38px;
    color: #fff;

    &::after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      margin-left: 30px;
      margin-bottom: 17px;
      background-color: $secondary-color;
    }
  }

  &-list {
    padding: 0 8.4vw;
    background-color: var(--main-color);
  }

  &-item {
    margin-bottom: 50px;

    &:hover {
      .home-blog-item-figure {
        img {
          transform: scale(1.1);
        }
      }
    }

    &-figure {
      margin-bottom: 20px;
      height: 250px;
      overflow: hidden;

      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: center;
        @include transition(7s);
      }
    }

    &-date {
      text-transform: uppercase;
      font-size: 14px;
      color: #fff;
    }

    &-title {
      line-height: 32px;
      font-weight: 500;
      font-size: 28px;
      color: #fff;
    }
  }

  @media (min-width: 768px) {
    padding-top: 195px;
    padding-bottom: 140px;

    &-title {
      margin-bottom: 60px;
    }

    &-list {
      padding-top: 40px;
      gap: 60px;
      display: flex;
      overflow-x: scroll;
      will-change: transform;
      user-select: none;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      cursor: grab;

      &.active {
        cursor: grabbing;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-item {
      max-width: 670px;
      width: 35vw;

      &-date {
        font-size: 16px;
      }

      &-title {
        font-size: 32px;
        line-height: 36px;
      }
    }
  }

  @media (min-width: 1300px) {
    padding-bottom: 200px;

    &-list {
      gap: 100px;
    }

    &-item {
      &-figure {
        height: 380px;

        img {
          height: 380px;
        }
      }
    }
  }
}